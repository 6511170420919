.cloud-container {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    /* display: flex; */
    align-items: center;
    justify-content: center;
  }
  
  ul.cloud {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    line-height: 2.75rem;
    width: 100%;
  }
  
  ul.cloud h1 {
    /*
    Not supported by any browser at the moment :(
    --size: attr(data-weight number);
    */
    --size: 4;
    color: black;
    font-size: calc(var(--size) * 20%);
    display: block;
    padding: 0.125rem 0.25rem;
    position: relative;
    text-decoration: none;
    cursor: pointer;
  }
  
  ul.cloud h1[data-weight="1"] { --size: 1; }
  ul.cloud h1[data-weight="2"] { --size: 2; }
  ul.cloud h1[data-weight="3"] { --size: 3; }
  ul.cloud h1[data-weight="4"] { --size: 4; }
  ul.cloud h1[data-weight="5"] { --size: 6; }
  ul.cloud h1[data-weight="6"] { --size: 8; }
  ul.cloud h1[data-weight="7"] { --size: 10; }
  ul.cloud h1[data-weight="8"] { --size: 13; }
  ul.cloud h1[data-weight="9"] { --size: 16; }
  
  @media (prefers-reduced-motion) {
    ul.cloud * {
      transition: none !important;
    }
  }
  
  @media screen and (min-width: 768px) {
    .fade-in { animation: fadeIn 3s; }
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  }
