html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'Baskerville Old Face', 'Times New Roman';
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navigation{
  font-family: Arial, Helvetica, sans-serif;
}

.btn {
  font-family: Arial;
  font-size: min(max(12px, 3.2vw), 16px);
}

.btn-white{
  background-color: white;
  border-color: black;
  color: black;
}

.btn-white:hover{
  background-color: whitesmoke;
}

.btn-white:disabled{
  background-color: rgb(235, 235, 235);
}

.btn-whitee:active {
  background-color: lightgrey;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none;
}

.ql-editor {
  font-family: 'Baskerville Old Face', 'Times New Roman';
  font-size: 20px;
}

#vineBox {
  font-size: 20px;
}

.story-container {
  padding-bottom: 100px;
}

#storyDiv a {
  color: blue;
}

#logo {
  width: 100%;
  height: 100%;
}

